import { Desktop } from "../Desktop";
import { FileManager } from "../FileManager";
import Notepad from "../Notepad";

let _os: AJoS | null = null;

interface FsItem {
  name: string;
  type: "dir" | "file";
}

interface FsFolder extends FsItem {
  type: "dir";
  children?: Array<FsFile | FsFolder>;
}

interface FsFile extends FsItem {
  type: "file";
  content: string | ArrayBuffer;
}

const initialFileStructure: FsFolder = {
  name: "/",
  type: "dir",
  children: [
    {
      name: "home",
      type: "dir",
      children: [
        {
          name: "documents",
          type: "dir",
          children: [],
        },
        {
          name: "desktop",
          type: "dir",
          children: [
            {
              name: "Coding Challenges",
              type: "dir",
              children: [
                {
                  name: "Bouncy Ball.shortcut",
                  type: "file",
                  content: /* json */ `{
                    "type": "text/x-uri",
                    "target": "https://aaronjspetner.com/bouncy-ball/index.html"
                  }`,
                },
              ],
            },
            {
              name: "LinkedIn.shortcut",
              type: "file",
              content: /* json */ `{
                "type": "text/x-uri",
                "target": "https://www.linkedin.com/in/aaronjspetner/"
              }`,
            },
            {
              name: "About.txt",
              type: "file",
              content: "This is a website that thinks it's an operating system. Not sure what else to say...",
            },
            {
              name: ".hidden.txt",
              type: "file",
              content: "Hidden file!",
            },
            {
              name: "#meta.txt",
              type: "file",
              content: "Meta file!",
            },
          ],
        },
        {
          name: "downloads",
          type: "dir",
        },
        {
          name: "music",
          type: "dir",
        },
        {
          name: "pictures",
          type: "dir",
        },
        {
          name: "videos",
          type: "dir",
        },
      ],
    },
    {
      name: "sys",
      type: "dir",
      children: [
        {
          name: ".version",
          type: "file",
          content: "0.0.1",
        }
      ]
    },
    {
      name: "lib",
      type: "dir",
    },
    {
      name: "tmp",
      type: "dir",
    },
    {
      name: "dev",
      type: "dir",
    },
    {
      name: "etc",
      type: "dir",
    },
  ],
};

class AJoS {
  #desktop: Desktop | null = null;
  #fsRoot: FileSystemDirectoryHandle | null = null;

  constructor() {
    if (_os) {
      return _os;
    }
    _os = this;

    this.#init();
  }

  async #init() {
    //TODO: Block UI until persisted
    if (!(await navigator.storage.persisted()))
      await navigator.storage.persist();

    this.#fsRoot = await navigator.storage.getDirectory();

    const initializeFolder = async (parentPath: string, folder: FsFolder) => {
      if (!folder.children) return;
      for (let i = 0; i < folder.children.length; i++) {
        const child = folder.children[i] as FsItem;
        const path = parentPath.replace(/\/$/, "") + "/" + child.name;
        if (child.type === "dir") {
          await this.getFolder(path, true);
          if ((child as FsFolder).children) {
            await initializeFolder(path, child as FsFolder);
          }
        } else if (child.type === "file") {
          const fileHandle = await this.getFile(path, true);
          const writeHandle = await fileHandle?.createWritable();
          await writeHandle?.write((child as FsFile).content);
          await writeHandle?.close();
        }
      }
    };
    await initializeFolder("/", initialFileStructure);

    document.body.appendChild((this.#desktop = new Desktop()));
  }

  async createFolder(path: string) {
    const dirs = path.split("/").filter((s) => s !== "");

    let currentDir = this.#fsRoot!;
    for (let i = 0; i < dirs.length; i++) {
      const dir = dirs[i];
      try {
        const dirHandle = await currentDir.getDirectoryHandle(dir, {
          create: true,
        });
        currentDir = dirHandle;
      } catch (e) {
        return null;
      }
    }
  }

  async getFolder(
    path: string,
    create: boolean = false
  ): Promise<FileSystemDirectoryHandle | null> {
    const dirs = path.split("/").filter((s) => s !== "");
    let currentDir = this.#fsRoot!;
    for (const dir of dirs) {
      try {
        currentDir = await currentDir.getDirectoryHandle(dir, { create });
        if (!currentDir) return null;
      } catch (e) {
        return null;
      }
    }
    return currentDir;
  }

  async getParentFolder(
    item: FileSystemHandle
  ): Promise<FileSystemDirectoryHandle> {
    const root = this.#fsRoot!;
    let pathArray = await root.resolve(item);
    if (!pathArray) {
      throw new Error("Invalid item");
    }
    if (pathArray.length < 2) return root;
    let parent: FileSystemDirectoryHandle = await root.getDirectoryHandle(
      pathArray[0]
    );

    for (let i = 1; i < pathArray.length - 1; i++) {
      parent = await parent.getDirectoryHandle(pathArray[i]);
    }

    return parent;
  }

  async getFilePath(item: FileSystemHandle): Promise<string> {
    const pathArray = await this.#fsRoot!.resolve(item);
    if (!pathArray) {
      throw new Error("Invalid item");
    }
    return "/" + pathArray.join("/");
  }

  async deleteFileSystemItem(item: FileSystemHandle) {
    const parent = await this.getParentFolder(item);
    await parent.removeEntry(item.name, { recursive: true });
  }

  async getFile(
    path: string,
    create: boolean = false
  ): Promise<FileSystemFileHandle | null> {
    const folderPath = path.split("/").slice(0, -1).join("/");
    const filePath = path.split("/").slice(-1)[0];
    const folder = await this.getFolder(folderPath, create);
    if (!folder) return null;
    const file = await folder.getFileHandle(filePath, { create });
    return file;
  }

  async launchFile(path: string) {
    const fileHandle = await this.getFile(path);
    const file = await fileHandle?.getFile();
    if (!file) {
      return; // TODO: error message
    }
    switch (file.type) {
      case "text/plain":
        this.#desktop?.registerWindow(Notepad, { filePath: path });
        break;
      default:
        switch (file.name.split(".").pop()) {
          case "shortcut":
            const shortcut = JSON.parse(await file.text());
            switch (shortcut.type) {
              case "text/x-uri":
                window.open(shortcut.target);
                break;
            }
            break;
        }
    }
  }

  async launchFolder(path: string) {
    this.#desktop?.registerWindow(FileManager, { path: path });
  }

  static get instance() {
    return _os;
  }

  get desktop() {
    return this.#desktop;
  }
}

export default AJoS;
