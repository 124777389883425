import AWindow from ".";
import style from "./awindow-titlebar.css?inline";

export default class AWindowTitleBar extends HTMLElement {
  #parent: AWindow;
  #shadowDom: ShadowRoot;
  #icon: HTMLElement;
  #iconCSS?: CSSStyleRule;
  #title: HTMLElement;
  #actionButtons: HTMLElement;
  #minButton: HTMLElement;
  #maxButton: HTMLElement;
  #closeButton: HTMLElement;

  constructor(
    window: AWindow,
    title: string,
    icon?: string | null | undefined
  ) {
    super();
    this.#parent = window;
    this.#shadowDom = this.attachShadow({ mode: "closed" });
    const css = new CSSStyleSheet();
    css.replaceSync(style);
    this.#shadowDom.adoptedStyleSheets.push(css);
    this.#iconCSS = css.cssRules[
      css.insertRule(/* css */ `ajs-window-title-icon {
        display: none;
      }`)
    ] as CSSStyleRule;
    this.icon = icon;

    this.#shadowDom.appendChild(
      (this.#icon = document.createElement("ajs-window-title-icon"))
    );

    this.#icon.addEventListener("click", () => {}); // TODO: Add menu

    this.#shadowDom.appendChild(
      (this.#title = document.createElement("ajs-window-title-text"))
    );
    this.title = title;

    this.#shadowDom.appendChild(
      (this.#actionButtons = document.createElement(
        "ajs-window-title-action-buttons"
      ))
    );
    this.#actionButtons.appendChild(
      (this.#minButton = document.createElement("ajs-window-title-button"))
    );
    this.#minButton.classList.add("min");

    this.#minButton.addEventListener("mousedown", (e) => {
      e.stopPropagation();
    });
    this.#minButton.addEventListener("click", (e) => {
      e.stopPropagation();
      alert("Not yet implemented.")
    });

    this.#actionButtons.appendChild(
      (this.#maxButton = document.createElement("ajs-window-title-button"))
    );
    this.#maxButton.classList.add("max");
    this.#maxButton.addEventListener("mousedown", (e) => {
      e.stopPropagation();
    });
    this.#maxButton.addEventListener("click", () => {
      this.#parent.maximize();
    });
    this.#actionButtons.appendChild(
      (this.#closeButton = document.createElement("ajs-window-title-button"))
    );
    this.#closeButton.classList.add("close");
    this.#closeButton.addEventListener("mousedown", (e) => {
      e.stopPropagation();
    });
    this.#closeButton.addEventListener("click", () => {
      this.#parent.close();
    });

    this.addEventListener("mousedown", (e) => {
      this.dispatchEvent(new MouseEvent("title-drag", e));
    });
  }

  set title(value: string) {
    this.#title.textContent = value;
  }

  set maximized(value: boolean) {
    if(value)
      this.#maxButton.classList.add("maximized");
    else
      this.#maxButton.classList.remove("maximized");
  
  }

  set icon(value: string | null | undefined) {
    if (value) {
      this.#iconCSS &&
        (this.#iconCSS.style.backgroundImage = `url("${value}")`);
      this.#iconCSS && (this.#iconCSS.style.display = "block");
    } else {
      this.#iconCSS && (this.#iconCSS.style.display = "none");
    }
  }
}
customElements.define("ajs-window-titlebar", AWindowTitleBar);
