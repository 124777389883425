import style from "./taskbar.css?inline";
import statusStyle from "./taskbar-status.css?inline";

import { Desktop, TaskIcon } from ".";
import AWindow from "../AWindow";

class StatusArea extends HTMLElement {
  #shadowDom: ShadowRoot;
  #timeBox: HTMLElement;
  #dateBox: HTMLElement;

  constructor() {
    super();
    this.#shadowDom = this.attachShadow({ mode: "closed" });
    const css = new CSSStyleSheet();
    css.replace(statusStyle);
    this.#shadowDom.adoptedStyleSheets.push(css);

    this.#shadowDom.appendChild(
      (this.#timeBox = document.createElement("ajs-status-time"))
    );
    this.#shadowDom.appendChild(
      (this.#dateBox = document.createElement("ajs-status-date"))
    );

    setInterval(() => {
      this.#timeBox.textContent = new Date().toLocaleTimeString(
        navigator.language,
        { timeStyle: "short" }
      );
      this.#dateBox.textContent = new Date().toLocaleDateString(
        navigator.language
      );
    }, 100);
  }
}
customElements.define("ajs-status-area", StatusArea);
export interface WindowTask {
  windowClass: WindowType<AWindow>;
  windows: AWindow[];
}

export default class Taskbar extends HTMLElement {
  #shadowDom: ShadowRoot;
  #startButton: HTMLElement;
  #iconArea: HTMLElement;
  #statusArea: HTMLElement;
  #windowTasks: WindowTask[] = [];

  constructor(_parent: Desktop) {
    super();
    this.#shadowDom = this.attachShadow({ mode: "closed" });
    const css = new CSSStyleSheet();
    css.replaceSync(style);
    this.#shadowDom.adoptedStyleSheets.push(css);

    this.#startButton = document.createElement("ajs-start-button");
    this.#shadowDom.appendChild(this.#startButton);

    this.#startButton.addEventListener("click", async () => {});

    this.#iconArea = document.createElement("ajs-icon-area");
    this.#shadowDom.appendChild(this.#iconArea);
    this.#statusArea = new StatusArea();
    this.#shadowDom.appendChild(this.#statusArea);
  }

  #renderTasks() {
    while(this.#iconArea.firstChild) {
      this.#iconArea.removeChild(this.#iconArea.firstChild);
    }
    this.#windowTasks.forEach((task) => {
      const taskIcon = new TaskIcon(task);
      //taskIcon.innerText = task.windowClass.name;
      //taskIcon.setAttribute("count", task.windows.length.toString());
      this.#iconArea.appendChild(taskIcon);
    });
  }

  addWindow(window: AWindow) {
    let windowTask = this.#windowTasks.find(
      (w) => w.windowClass === window.constructor
    );
    if (!windowTask) {
      windowTask = { windowClass: window.constructor as WindowType<AWindow>, windows: [] };
      this.#windowTasks.push(windowTask);
    }
    windowTask.windows.push(window);
    this.#renderTasks();
  }

  removeWindow(window: AWindow) {
    let windowTask = this.#windowTasks.find(
      (w) => w.windowClass === window.constructor
    );

    if (windowTask) {
      windowTask.windows.splice(windowTask.windows.indexOf(window), 1);
      if (windowTask.windows.length === 0) {
        this.#windowTasks.splice(this.#windowTasks.indexOf(windowTask), 1);
      }
    }
    this.#renderTasks();
  }
}
customElements.define("ajs-taskbar", Taskbar);
