import AWindow, { AWindowInitOptions, AWindowParams } from "../AWindow";
import style from "./file-manager.css?inline";
import FolderIcon from "../lib/icons/folder.svg";
import ParentFolderIcon from "./icons/parent-folder.svg";
import { Desktop } from "../Desktop";
import { IconContainer } from "../IconContainer";

interface FileManagerParams extends AWindowParams {
  path: string;
}
export default class FileManager extends AWindow {
  #path: string;

  //#parent: Desktop | undefined;
  #shadowDom: ShadowRoot | undefined;
  //#titleBar: HTMLElement | undefined;
  #iconContainer: IconContainer | undefined;
  #mainArea: HTMLElement | undefined;
  #upButton: HTMLButtonElement | undefined;
  #pathBreadcrumbs: HTMLElement | undefined;

  #initialized: Boolean = false;

  constructor(parent: Desktop, params: FileManagerParams) {
    super(parent, params);

    this.icon = FolderIcon;
    this.#path = params.path;
  }

  public init({  shadowDom,  mainArea }: AWindowInitOptions) {
    setTimeout(async () => {
      if (this.#initialized) return;
      this.#initialized = true;

      //this.#parent = parent;
      this.#shadowDom = shadowDom;
      //this.#titleBar = titleBar;
      this.#mainArea = mainArea;

      const css = new CSSStyleSheet();
      css.replaceSync(style);
      this.#shadowDom!.adoptedStyleSheets.push(css);

      css.insertRule(/* css */ `
        button.up {
          background-image: url("${ParentFolderIcon}");
        }
      `);
      css.insertRule(/* css */ `
        ajs-icon-container {
          overflow: auto;
        }
      `);

      const toolBar = this.#mainArea!.appendChild(
        document.createElement("ajs-window-toolbar")
      );
      this.#upButton = toolBar.appendChild(document.createElement("button"));
      this.#upButton.classList.add("up");
      this.#pathBreadcrumbs = toolBar.appendChild(
        document.createElement("ajs-toolbar-path-breadcrumbs")
      );

      this.#iconContainer = this.#mainArea!.appendChild(
        new IconContainer(this, this.#path, {
          sortBy: IconContainer.FileAttributes.Name,
          allowedDisplayAttributes: IconContainer.DisplayAttributes.All,
          display: IconContainer.DisplayAttributes.LargeIcons,
          sortDir: 1,
          iconDirection: "row",
        })
      );

      this.#upButton.addEventListener("click", () => {
        this.#path = this.#path.substring(0, this.#path.lastIndexOf("/"));
        if (this.#path === "") this.#path = "/";
        this.#iconContainer!.path = this.#path;
      });

      this.#iconContainer.addEventListener("path-change", () => {
        setTimeout(() => {
          this.#init();
        });
        this.#path = this.#iconContainer!.path;
      });

      this.#init();
    });
  }

  #init() {
    this.#upButton!.disabled = this.#iconContainer!.path === "/";
    while (this.#pathBreadcrumbs!.firstChild) {
      this.#pathBreadcrumbs!.firstChild.remove();
    }

    this.#path.split("/").forEach((pathPart, index, pathParts) => {
      if (pathPart === "" && index > 0) return;
      const pathPartElement = this.#pathBreadcrumbs!.appendChild(
        document.createElement("ajs-toolbar-path-breadcrumb-item")
      );
      pathPartElement.innerText = pathPart === "" ? "/" : pathPart;
      if (index > 0 && index !== pathParts.length - 1)
        this.#pathBreadcrumbs!.appendChild(document.createTextNode("/"));

      if (index < pathParts.length - 1)
        pathPartElement.addEventListener("click", () => {
          this.#path = this.#path
            .split("/")
            .filter((_, i) => i < index + 1)
            .join("/");
          this.#iconContainer!.path = this.#path;
        });
    });

    this.focus();
  }

  static get icon() {
    return FolderIcon;
  }
}

customElements.define("ajs-file-manager", FileManager);
