import NotepadIcon from "./notepad.svg";
import style from "./notepad.css?inline";
import AWindow, { AWindowInitOptions, AWindowParams } from "../AWindow";
import { Desktop } from "../Desktop";
import AJoS from "../AJoS";

interface NotepadParams extends AWindowParams {
  filePath?: string | null;
}
class Notepad extends AWindow {
  //#parent: Desktop | undefined;
  #shadowDom: ShadowRoot | undefined;
  //#titleBar: HTMLElement | undefined;
  #mainArea: HTMLElement | undefined;
  #textArea: HTMLTextAreaElement | undefined;
  //#file: File | null = null;
  #filePath: string | null = null;
  //#title: string = "Untitled - Notepad";
  #text: string = "";
  //#fileName?: string;

  #initialized: boolean = false;

  constructor(parent: Desktop, params: NotepadParams) {
    super(parent, params);
    this.icon = NotepadIcon;
    this.#filePath = params.filePath ?? null;

    if (!this.#shadowDom) {
      return;
    }
  }

  get text(): string {
    return this.#text;
  }

  set text(value: string) {
    this.#text = value;
    this.#textArea && (this.#textArea.value = value);
  }

  // Fake constructor to emulate protected members of base class
  public init({  shadowDom,  mainArea }: AWindowInitOptions) {
    setTimeout(async () => {
      if (this.#initialized) return;
      this.#initialized = true;

      //this.#parent = parent;
      this.#shadowDom = shadowDom;
      //this.#titleBar = titleBar;
      this.#mainArea = mainArea;

      const css = new CSSStyleSheet();
      css.replaceSync(style);
      this.#shadowDom.adoptedStyleSheets.push(css);

      if (this.#filePath) {
        const fileHandle = await AJoS.instance?.getFile(this.#filePath);
        const file = await fileHandle?.getFile();
        this.text = (await file?.text()) ?? "";
        this.title = this.#filePath.substring(
          this.#filePath.lastIndexOf("/") + 1
        );
        this.focus();
      } else {
        this.#text = "";
      }

      this.#textArea = document.createElement("textarea");
      this.#textArea.spellcheck = false;
      this.#textArea.value = this.#text;
      this.#textArea.addEventListener("keydown", (e) => {
        switch (e.key) {
          case "Tab":
            if (!document.execCommand("insertText", false, "\t")) {
              this.#textArea!.setRangeText(
                "\t",
                this.#textArea!.selectionStart || 0,
                this.#textArea!.selectionEnd || 0,
                "end"
              );
              this.#textArea!.dispatchEvent(
                new InputEvent("input", {
                  data: "\t",
                  inputType: "insertText",
                })
              );
            }

            break;
          default:
            e.stopPropagation();
        }
      });
      this.#mainArea.appendChild(this.#textArea);
    });
  }

  focus(options?: FocusOptions | undefined): void {
    super.focus(options);
    setTimeout(() => {
      this.#textArea?.focus(options);
    });
  }

  close(): void {
    //if (confirm("Are you sure?"))
    super.close();
  }

  get initialized() {
    return this.#initialized;
  }

  static get icon(){
    return NotepadIcon;
  }
}

customElements.define("ajs-notepad", Notepad);

export default Notepad;
