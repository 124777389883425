import { WindowTask } from ".";
import style from "./task-icon.css?inline";

export default class TaskIcon extends HTMLElement {
  #shadowDom: ShadowRoot;
  //#task: WindowTask

  constructor(task:WindowTask){
    super();
    this.#shadowDom = this.attachShadow({ mode: "closed" });
    const css = new CSSStyleSheet();
    css.replaceSync(style);
    this.#shadowDom.adoptedStyleSheets.push(css);

    css.insertRule(/* css */ `
      :host{
        --task-icon: url(${task.windowClass.icon});
      }
    `);
    //this.#task = task;

  
  }
}
customElements.define('ajs-task-icon', TaskIcon);