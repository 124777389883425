import AJoS from "../AJoS";
import style from "./icon-container.css?inline";
import { Icon } from "../Icon";
import NotepadIcon from "../Notepad/notepad.svg";
import FolderIcon from "../lib/icons/folder.svg";
import HyperlinkIcon from "../lib/icons/hyperlink.svg";
import FolderContentsIcon from "../lib/icons/folder-contents.svg";
import HomeIcon from "../lib/icons/home.svg";
import TrashIcon from "../lib/icons/trash-empty.svg";
import WindowIcon from "../lib/icons/window.svg";
import { Desktop } from "../Desktop";
import AWindow from "../AWindow";

enum DisplayAttributes {
  LargeIcons = 1 << 0,
  SmallIcons = 1 << 1,
  Details = 1 << 2,
  All = LargeIcons | SmallIcons | Details,
}

enum FileAttributes {
  Name,
  LastModified,
  Type,
  Size,
}
interface IconContainerOptions {
  sortBy: FileAttributes;
  sortDir: -1 | 1;
  allowedDisplayAttributes: DisplayAttributes;
  display:
    | DisplayAttributes.LargeIcons
    | DisplayAttributes.SmallIcons
    | DisplayAttributes.Details;
  iconDirection: "row" | "column";
}

//  * @class IconContainer
//  * @classdesc The container for the icon
//  * @property {string}
export default class IconContainer extends HTMLElement {
  #parent: Desktop | AWindow;
  #path: string;
  #sortBy: FileAttributes;
  #sortDir: -1 | 1;
  #allowedDisplayAttributes: DisplayAttributes;
  #display: IconContainerOptions["display"];
  #iconDirection: IconContainerOptions["iconDirection"];
  #shadowDom: ShadowRoot;
  #windowed: boolean;

  static FileAttributes = FileAttributes;
  static DisplayAttributes = DisplayAttributes;

  constructor(
    parent: Desktop | AWindow,
    path: string,
    {
      sortBy = FileAttributes.Name,
      sortDir = 1,
      allowedDisplayAttributes = DisplayAttributes.All,
      display = DisplayAttributes.LargeIcons,
      iconDirection = "row",
    }: IconContainerOptions
  ) {
    super();

    this.#parent = parent;
    this.#windowed = parent instanceof AWindow;
    this.#path = path;
    this.#sortBy = sortBy;
    this.#sortDir = sortDir;
    this.#allowedDisplayAttributes = allowedDisplayAttributes;
    this.#display = display;
    this.#iconDirection = iconDirection;
    this.#shadowDom = this.attachShadow({ mode: "closed" });
    const css = new CSSStyleSheet();
    css.replaceSync(style);
    this.#shadowDom.adoptedStyleSheets.push(css);
    css.insertRule(/* css */ `
      :host {
        --direction: ${iconDirection};
      }
    `);

    this.#init();
  }

  async #init() {
    while (this.#shadowDom.firstChild) {
      this.#shadowDom.firstChild.remove();
    }
    const folderHandle = await AJoS.instance?.getFolder(this.path);

    if (!folderHandle) return; //TODO: add error handling

    this.#parent.title = folderHandle?.name === "" ? "/" : folderHandle?.name;

    let folderContents: {
      name: string;
      handle?: FileSystemDirectoryHandle | FileSystemFileHandle | null;
    }[] = [];
    for await (const [name, handle] of folderHandle?.entries()) {
      if (![".", "#"].includes(name.substring(0, 1)))
        folderContents.push({ name, handle });
    }

    if (this.path === "/home/desktop")
      folderContents.unshift({ name: "..home" }, { name: "..trash" });

    for (const { name, handle } of folderContents.sort((a, b) => {
      if (!a.handle || !b.handle) {
        if (!a.handle && b.handle) return -1;
        if (!b.handle && a.handle) return 1;
        return 0;
      }

      if (a.handle.kind === "directory" && b.handle.kind === "file") return -1;
      if (a.handle.kind === "file" && b.handle.kind === "directory") return 1;
      if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
      if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
      return 0;
    })) {
      let iconFile = WindowIcon;
      let title;

      if (name === "..home") {
        title = "Home";
        iconFile = HomeIcon;
      } else if (name === "..trash") {
        title = "Trash";
        iconFile = TrashIcon;
      } else if (handle?.kind === "directory") {
        const entry = await (await handle.entries()).next();
        if (entry.done) {
          iconFile = FolderIcon;
        } else {
          iconFile = FolderContentsIcon;
        }
      } else if (handle) {
        const file = await handle.getFile();
        switch (file.type) {
          case "text/plain":
            iconFile = NotepadIcon;
            break;
          default:
            switch (file.name.split(".").pop()) {
              case "shortcut":
                const shortcut = JSON.parse(await file.text());
                switch(shortcut.type){
                  case "text/x-uri":
                    iconFile = HyperlinkIcon;
                  break;
                }
                break;
            }
        }
      }
      const icon = this.#shadowDom.appendChild(
        new Icon(
          this,
          iconFile,
          handle ? await AJoS.instance!.getFilePath(handle) : name,
          handle?.kind ?? "directory",
          title
        )
      );
      icon.addEventListener("keydown", (e) => {
        e.preventDefault();
        switch (e.key) {
          case "ArrowDown":
          case "ArrowUp":
          case "ArrowLeft":
          case "ArrowRight":
            this.#iconNavigate(icon, e.key);
            break;
          case "Enter":
            break;
        }
      });

      icon.addEventListener("dblclick", async () => {
        if (handle?.kind === "file") {
          await AJoS.instance?.launchFile(icon.path);
        } else {
          let path;
          if (handle?.kind === "directory") {
            path = icon.path;
          } else {
            //Special folder
            switch (icon.path) {
              case "..home":
                path = "/home";
                break;
              default:
                alert("Not yet implemented.");
                return;
            }
          }
          if (this.#windowed) this.path = path;
          else await AJoS.instance?.launchFolder(path);
        }
      });
    }
  }

  get path() {
    return this.#path;
  }
  set path(path: string) {
    this.#path = path;
    this.#init();
    this.dispatchEvent(new Event("path-change"));
  }
  get sortBy() {
    return this.#sortBy;
  }
  set sortBy(sortBy: FileAttributes) {
    this.#sortBy = sortBy;
  }
  get sortDir() {
    return this.#sortDir;
  }
  set sortDir(sortDir: -1 | 1) {
    this.#sortDir = sortDir;
  }
  get allowedDisplayAttributes() {
    return this.#allowedDisplayAttributes;
  }
  set allowedDisplayAttributes(allowedDisplayAttributes: DisplayAttributes) {
    this.#allowedDisplayAttributes = allowedDisplayAttributes;
  }
  get display() {
    return this.#display;
  }
  set display(display: IconContainerOptions["display"]) {
    this.#display = display;
  }

  #iconNavigate(
    icon: Icon,
    key: "ArrowDown" | "ArrowUp" | "ArrowLeft" | "ArrowRight"
  ) {
    const rows = getComputedStyle(this).gridTemplateRows.split(" ").length;
    const cols = getComputedStyle(this).gridTemplateColumns.split(" ").length;

    const icons = Array.from(
      this.#shadowDom.querySelectorAll("ajs-icon")
    ) as Icon[];

    const curIndex = icons.indexOf(icon);
    let nextIndex = curIndex;

    if (this.#iconDirection === "column") {
      const curRow = curIndex % rows;
      const curCol = Math.floor(curIndex / rows);

      switch (key) {
        case "ArrowDown":
          if (curRow <= rows - 2 && curIndex < icons.length - 1)
            nextIndex = curIndex + 1;
          break;
        case "ArrowUp":
          if (curRow >= 1) nextIndex = curIndex - 1;
          break;
        case "ArrowRight":
          if (curCol <= cols - 2 && curIndex <= icons.length / rows)
            nextIndex = curIndex + rows;
          break;
        case "ArrowLeft":
          if (curCol >= 1) nextIndex = curIndex - rows;
          break;
      }
    } else {
      const curRow = Math.floor(curIndex / cols);

      switch (key) {
        case "ArrowDown":
          if (curRow <= rows - 2 && curIndex < icons.length - 1)
            nextIndex = Math.min(curIndex + cols, icons.length - 1);
          break;
        case "ArrowUp":
          if (curRow >= 1) nextIndex = curIndex - cols;
          break;
        case "ArrowRight":
          if (curIndex <= icons.length - 2) nextIndex = curIndex + 1;
          break;
        case "ArrowLeft":
          if (curIndex >= 1) nextIndex = curIndex - 1;
          break;
      }
    }
    const nextIcon = icons[nextIndex];
    nextIcon.focus();
  }
}
customElements.define("ajs-icon-container", IconContainer);
