import style from "./desktop.css?inline";
import { Taskbar } from ".";
import AWindow, { AWindowParams } from "../AWindow";
import { IconContainer } from "../IconContainer";

const POS_SPACING = 80;
const MAX_POS = 5;

// interface WindowReference {
//   window: AWindow;
//   zIndex: number;
// }
export default class Desktop extends HTMLElement {
  #shadowDom: ShadowRoot;
  #taskbar: Taskbar;
  #container: HTMLElement;
  #windows: AWindow[] = [];
  #lastX: number = 0;
  #lastY: number = 0;

  constructor() {
    super();

    this.#shadowDom = this.attachShadow({ mode: "closed" });
    this.#eventOverrides();
    const css = new CSSStyleSheet();
    css.replaceSync(style);
    this.#shadowDom.adoptedStyleSheets.push(css);

    this.#container = this.#shadowDom.appendChild(
      document.createElement("main")
    );
    this.#container.appendChild(
      new IconContainer(this, "/home/desktop", {
        sortBy: IconContainer.FileAttributes.Name,
        allowedDisplayAttributes: IconContainer.DisplayAttributes.LargeIcons,
        display: IconContainer.DisplayAttributes.LargeIcons,
        iconDirection: "column",
        sortDir: 1,
      })
    );
    this.#taskbar = this.#shadowDom.appendChild(new Taskbar(this));
  }

  #eventOverrides() {
    this.addEventListener("contextmenu", (e) => e.preventDefault());
  }

  get width() {
    return this.#container.clientWidth;
  }
  get height() {
    return this.#container.clientHeight;
  }

  #getMaxZIndex() {
    return Math.max(...this.#windows.map((w) => w.zIndex));
  }

  registerWindow(
    WindowClass: typeof AWindow,
    params: Record<string | number, any>
  ) {
    const defaultY =
      this.#lastY + POS_SPACING > POS_SPACING * MAX_POS
        ? POS_SPACING
        : this.#lastY + POS_SPACING;
    this.#lastY = defaultY;

    const defaultX =
      this.#lastX + POS_SPACING > POS_SPACING * MAX_POS * defaultY
        ? MAX_POS * defaultY
        : this.#lastX + POS_SPACING;
    this.#lastX = defaultX;

    const windowParams: AWindowParams = {
      ...params,
      defaultX,
      defaultY,
      defaultZIndex: this.#getMaxZIndex() + 1,
    };
    const window = this.#container.appendChild(
      new WindowClass(this, windowParams)
    );
    this.#windows.push(window);
    window.addEventListener("mousedown", () => {
      window.focus();
    });
    window.addEventListener("focus", () => {
      window.zIndex = this.#getMaxZIndex() + 1;
      this.#windows
        .sort((a, b) => a.zIndex - b.zIndex)
        .forEach((w, i) => (w.zIndex = i + 1));
    });
    this.#taskbar.addWindow(window);
  }

  unregisterWindow(window: AWindow) {
    this.#windows = this.#windows.filter((w) => w !== window);
    this.#container.removeChild(window);
    if (this.#windows.length === 0) {
      this.#lastX = 0;
      this.#lastY = 0;
    }
    this.#taskbar.removeWindow(window);
  }

  // Void property to prevent HTML tooltip
  set title(_value: string) {}

  get windows() {
    return this.#windows;
  }
}
customElements.define("ajs-desktop", Desktop);
