import style from "./icon.css?inline";
import ShortcutIcon from "../lib/icons/shortcut.svg";
import { IconContainer } from "../IconContainer";

const HIDDEN_EXTENSIONS = ["shortcut"]

export default class Icon extends HTMLElement {
  #shadowDom: ShadowRoot;
  #iconText: HTMLElement;
  //#iconRule: CSSStyleRule;
  //#iconImage: HTMLElement;
  #path: string;
  //#kind: "directory" | "file";

  constructor(
    _parent: IconContainer,
    icon: string,
    path: string,
    _kind: "directory" | "file",
    title?: string
  ) {
    super();
    this.#path = path;
    //this.#kind = kind;

    this.tabIndex = 0;
    this.#shadowDom = this.attachShadow({ mode: "closed" });
    const css = new CSSStyleSheet();
    css.replaceSync(style);
    this.#shadowDom.adoptedStyleSheets.push(css);

    this.#shadowDom.appendChild(
      (document.createElement("ajs-icon-image"))
    );
    this.#shadowDom.appendChild(
      (this.#iconText = document.createElement("ajs-icon-text"))
    );

    if (!title) {
      title = path.substring(path.lastIndexOf("/") + 1);
    }

    if(HIDDEN_EXTENSIONS.includes(this.#path.split(".").pop() ?? ".")){
      title = title.substring(0, title.lastIndexOf("."));
    }

    this.#iconText.textContent = title;
    
    super.title = title;

    css.cssRules[
      css.insertRule(/* css */ `
        ajs-icon-image {
          background-image: url("${icon}");
        }        
      `)
    ] as CSSStyleRule;

    if(this.#path.split(".").pop() === "shortcut"){
      css.insertRule(/* css */ `
        ajs-icon-image::after {
          content: '';
          position: absolute;
          background-image: url("${ShortcutIcon}");
          background-size: contain;
          background-repeat: no-repeat;
          bottom: 0px;
          left: 0px;
          width: 20px;
          height: 20px;        
        }
      `);
    }

  }

  get path() {
    return this.#path;
  }
}

customElements.define("ajs-icon", Icon);
