import style from "./awindow.css?inline";
import WindowIcon from "../lib/icons/window.svg";
import { Desktop } from "../Desktop";
import { AWindowTitleBar } from ".";

const BORDER_WIDTH: number = 4;
const CORNER_LENGTH: number = 24;

const MIN_WIDTH: number = 300;
const MIN_HEIGHT: number = 200;

interface AWindowOptions {
  hasIcon?: boolean;
}

interface AWindowParams extends Record<string | number, any> {
  defaultX: number;
  defaultY: number;
  defaultZIndex: number;
}

interface AWindowInitOptions {
  parent: Desktop;
  shadowDom: ShadowRoot;
  titleBar: AWindowTitleBar;
  //titleBarIcon: HTMLImageElement | undefined;
  //titleBarText: HTMLSpanElement;
  mainArea: HTMLElement;
}

enum ResizeDirection {
  N = 1 << 0,
  E = 1 << 1,
  S = 1 << 2,
  W = 1 << 3,
  NW = N | W,
  NE = N | E,
  SW = S | W,
  SE = S | E,
}

class AWindow extends HTMLElement {
  #parent: Desktop;
  #shadowDom: ShadowRoot;
  #titleBar: AWindowTitleBar;
  #titleBarIcon: string = WindowIcon;
  #title: string = "";
  #mainArea: HTMLElement;
  #cssSize?: CSSStyleRule;
  #cssPosition?: CSSStyleRule;
  #dragPoint: Point | null | undefined;
  #resizeable: boolean = true;
  #borders?: HTMLElement[];
  #resizing: {
    type: ResizeDirection;
    originalPosition: {
      left: number;
      top: number;
      width: number;
      height: number;
    };
  } | null = null;

  constructor(
    parent: Desktop,
    params: AWindowParams,
    {}: AWindowOptions = { hasIcon: true }
  ) {
    super();
    this.#parent = parent;
    this.#shadowDom = this.attachShadow({ mode: "closed" });

    const css = new CSSStyleSheet();
    css.replaceSync(style);
    this.#shadowDom.adoptedStyleSheets.push(css);

    this.#cssSize = css.cssRules[
      css.insertRule(/* css */ `
        :host {
          width: 800px;
          height: 500px;
        }
      `)
    ] as CSSStyleRule;

    this.#cssPosition = css.cssRules[
      css.insertRule(/* css */ `
        :host {
          left: ${params.defaultX}px;
          top: ${params.defaultY}px;
          z-index: 0;
        }      
      `)
    ] as CSSStyleRule;

    css.insertRule(/* css */ `
      ajs-window-border {
        --border-width: ${BORDER_WIDTH}px;
        --corner-length: ${CORNER_LENGTH}px;
      }
    `);

    this.#titleBar = new AWindowTitleBar(this, "");
    this.#shadowDom.appendChild(this.#titleBar);

    this.#mainArea = document.createElement("main");
    this.#shadowDom.appendChild(this.#mainArea);

    this.icon = this.#titleBarIcon;

    if (this.#resizeable) {
      this.#borders = ["top", "right", "bottom", "left"].map((side) => {
        const border = document.createElement("ajs-window-border");
        border.classList.add(side);
        this.#shadowDom.appendChild(border);
        return border;
      });
    }

    this.init({
      parent: this.#parent,
      shadowDom: this.#shadowDom,
      titleBar: this.#titleBar,
      mainArea: this.#mainArea,
    });

    this.#eventOverrides();
    this.focus();
  }

  focus(options?: FocusOptions | undefined): void {
    super.focus(options);
    this.dispatchEvent(new CustomEvent("focus"));
  }

  close() {
    this.#parent.unregisterWindow(this);
  }

  get width() {
    return this.offsetWidth;
  }
  set width(width) {
    this.#cssSize && (this.#cssSize.style.width = `${width}px`);
  }

  get height() {
    return this.offsetHeight;
  }
  set height(height) {
    this.#cssSize && (this.#cssSize.style.height = `${height}px`);
  }

  get top() {
    return this.offsetTop;
  }
  set top(top) {
    this.#cssPosition && (this.#cssPosition.style.top = `${top}px`);
  }

  get left() {
    return this.offsetLeft;
  }
  set left(left) {
    this.#cssPosition && (this.#cssPosition.style.left = `${left}px`);
  }

  get zIndex() {
    return parseInt(window.getComputedStyle(this).zIndex);
  }
  set zIndex(zIndex) {
    this.#cssPosition && (this.#cssPosition.style.zIndex = `${zIndex}`);
  }

  get title() {
    return this.#title;
  }
  set title(value) {
    this.#title = value;
    this.#titleBar.title = value;
  }

  get icon() {
    return this.#titleBarIcon;
  }
  set icon(value) {
    this.#titleBarIcon = value;
    this.#titleBar.icon = value;
  }

  #eventOverrides() {
    this.#titleBar.addEventListener("mousedown", (e) => {
      this.#dragPoint = { x: e.clientX - this.left, y: e.clientY - this.top };
      return false;
    });
    this.#borders?.forEach((border) =>
      border.addEventListener("mousedown", (e) => {
        setTimeout(
          this.#resizeStart.bind(this),
          undefined,
          e.currentTarget,
          e.offsetX,
          e.offsetY
        );
      })
    );

    document.addEventListener("mousemove", (e) => {
      if (this.#dragPoint) {
        const left = e.clientX - this.#dragPoint.x;
        const top = e.clientY - this.#dragPoint.y;

        if (e.clientX - 10 < 0 || e.clientX + 20 > this.#parent.width) return;
        if (e.clientY - 10 < 0 || e.clientY + 20 > this.#parent.height) return;

        this.left = left;
        this.top = top;
      } else if (this.#resizing) {
        if (e.clientX - 10 < 0 || e.clientX + 20 > this.#parent.width) return;
        if (e.clientY - 10 < 0 || e.clientY + 20 > this.#parent.height) return;
        let newLeft = this.left;
        let newTop = this.top;
        let newWidth = this.width;
        let newHeight = this.height;

        const { type } = this.#resizing;

        if (type & ResizeDirection.N) {
          newTop = e.clientY;
          newHeight = Math.max(this.height - (newTop - this.top), MIN_HEIGHT);
        }
        if (type & ResizeDirection.S) {
          newHeight = Math.max(e.clientY - this.top, MIN_HEIGHT);
        }
        if (type & ResizeDirection.W) {
          newLeft = e.clientX;
          newWidth = Math.max(this.width - (newLeft - this.left), MIN_WIDTH);
        }
        if (type & ResizeDirection.E) {
          newWidth = Math.max(e.clientX - this.left, MIN_WIDTH);
        }
        this.left = newLeft;
        this.top = newTop;
        this.width = newWidth;
        this.height = newHeight;
      }
    });
    document.addEventListener("mouseup", () => {
      this.#dragPoint = null;
      setTimeout(() => {
        this.#resizing = null;
      });
    });
    this.addEventListener("keydown", (e) => {
      e.preventDefault();
    });
  }

  #resizeStart(border: HTMLElement, x: number, y: number) {
    const type = border.classList[0]!;
    let resizeDirection: ResizeDirection;
    switch (type) {
      case "top":
        resizeDirection = ResizeDirection.N;
        if (x < CORNER_LENGTH) resizeDirection |= ResizeDirection.W;
        else if (x > this.offsetWidth - CORNER_LENGTH)
          resizeDirection |= ResizeDirection.E;
        break;
      case "right":
        resizeDirection = ResizeDirection.E;
        if (y < CORNER_LENGTH) resizeDirection |= ResizeDirection.N;
        else if (y > this.offsetHeight - CORNER_LENGTH)
          resizeDirection |= ResizeDirection.S;
        break;
      case "bottom":
        resizeDirection = ResizeDirection.S;
        if (x < CORNER_LENGTH) resizeDirection |= ResizeDirection.W;
        else if (x > this.offsetWidth - CORNER_LENGTH)
          resizeDirection |= ResizeDirection.E;
        break;
      case "left":
        resizeDirection = ResizeDirection.W;
        if (y < CORNER_LENGTH) resizeDirection |= ResizeDirection.N;
        else if (y > this.offsetHeight - CORNER_LENGTH)
          resizeDirection |= ResizeDirection.S;
        break;
      default:
        return;
    }

    this.#resizing = {
      type: resizeDirection,
      originalPosition: {
        left: this.left,
        top: this.top,
        width: this.width,
        height: this.height,
      },
    };
  }

  maximize() {
    if (this.classList.contains("maximized")) {
      this.classList.add("unmaximized");
      this.classList.remove("maximized");
      setTimeout(() => {
        // Allow transitions when restoring, but not resizing or moving
        this.classList.remove("unmaximized");
      }, 500);
      this.#titleBar.maximized = false;
    } else {
      this.classList.add("maximized");
      this.#titleBar.maximized = true;
    }
  }

  static get icon() {
    return WindowIcon;
  }

  // Fake constructor to emulate protected members of base class
  public init({}: AWindowInitOptions) {}
}

export default AWindow;
export type { AWindowInitOptions, AWindowParams };
